//
// Layouts
//

// Navbar

.navbar {
	color: $white;
	background-color: $primary;
	
	.navbar-brand {
		color: $white;
	}
	
	.share {
		display: none;
	}
}

.navbar-nav {
	margin-top: $paragraph-margin-bottom;
	
	.nav-item {
		border-bottom: solid $border-width rgba($green-800, .125);
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.nav-link {
		&:not(:hover),
		&:not(:focus) {
			color: $white;
		}
		
		&:hover,
		&:focus {
			color: rgba($white, .5);
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar-toggler {
		display: none;
	}
	
	.navbar-collapse {
		display: block !important;
		flex-basis: auto;
		flex-grow: inherit;
	}
	
	.navbar-nav {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 0;
		
		.nav-item {
			border: 0;
		}
		
		.nav-link {
			position: relative;
			padding: $nav-link-padding-y $nav-link-padding-x;
			
			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 2px;
				background-color: $white;
				opacity: 0;
				@include transition($transition-base);
				transform: translate3d(0, 100%, 0);
			}
			
			&:hover::after {
				opacity: 1;
				transform: translate3d(0, -7px, 0);
			}
		}
	}
}

// Footer

.footer {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
	color: $white;
	background-color: $body-color;
	@include font-size($font-size-sm);
}

.credit {
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width * .5;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $body-color;
		@include border-radius($border-radius-pill);
	}
}

// Content

.content {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
}

.main,
.side {
	@include make-col-ready();
}

.main {
	margin-bottom: $grid-gutter-width;
}

@include media-breakpoint-up(md) {
	.content {
		padding-top: $grid-gutter-width * 2;
		padding-bottom: $grid-gutter-width * 2;
	}
	
	.main {
		margin-bottom: 0;
		@include make-col(8, $grid-columns);
	}
	
	.side {
		@include make-col(4, $grid-columns);
	}
}

@include media-breakpoint-up(xl) {
	.main {
		@include make-col(9, $grid-columns);
	}
	
	.side {
		@include make-col(3, $grid-columns);
	}
}
